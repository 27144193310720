@tailwind base;
@tailwind components;
@tailwind utilities;

a:-webkit-any-link {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
a:-webkit-any-link:hover {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

/* for desktop */
.whatsapp_float {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 100px;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  transition: all 0.1s cubic-bezier(0.42, 0, 0.58, 1);
}
.whatsapp_float:hover {
  transform: scale(1.1);
  box-shadow: 1px 1px #999;
}
.whatsapp-icon {
  margin-top: 16px;
  color: #fff;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 15px;
  }

  .whatsapp_float {
    width: 50px;
    height: 50px;
    bottom: 60px;
    right: 10px;
    font-size: 22px;
  }
}
